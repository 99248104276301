import React, { Component, useEffect, useState } from "react";
import { PrinterOutlined } from "@ant-design/icons";
import { Card, Table, Button, Typography, message } from "antd";
import NumberFormat from "react-number-format";
import { useParams, Link } from "react-router-dom";
import { app } from "../../../auth/FirebaseAuth";
import AvatarStatus from "components/shared-components/AvatarStatus";
import moment from "moment";
import { useHistory } from "react-router-dom";
import ReturnRequest from "./ReturnRequest";
const { Text } = Typography;
const invoiceData = [{ key: 0, product: "xxx", quantity: 21 }];

const OrderDetails = props => {
    let history = useHistory();

    const { id } = useParams();
    const [orderData, setOrderData] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        // You need to restrict it at some point
        // This is just dummy code and should be replaced by actual
        const data = app
            .firestore()
            .collection("orders")
            .doc(id)
            .onSnapshot(snap => {
                console.log(snap.data());
                if (snap.exists) {
                    // if (snap.data().storeID != props.userId) {
                    //     history.push("/error");
                    // }
                    let list = [];
                    let p = snap.data().orderItemPrice;
                    if (
                        snap.data().orderItemPrice > snap.data().itemDiscountedPrice
                    ) {
                        p = snap.data().itemDiscountedPrice;
                    }
                    list.push({ ...snap.data(), id, p });
                    setOrderData(list);
                    setLoading(false);
                } else {
                    history.push("/error");
                }
            });

        return () => data();
    }, []);

    const getPaymentStatus = status => {
        if (status === "Paid") {
            return "success";
        }
        if (status === "Hold") {
            return "warning";
        }
        if (status === "Canceled" || status === "Refunded") {
            return "error";
        }
        return "";
    };

    const getShippingStatus = status => {
        if (
            status === "Out for delivery" ||
            status === "Processing" ||
            status === "Pending store approval"
        ) {
            return "warning";
        }

        if (status === "Delivered") {
            return "success";
        }
        if (status === "store Refusal" || status === "Store Refusal") {
            return "danger";
        }
        return "";
    };
    const tableColumns = [
        {
            title: "Product",
            dataIndex: "itemTitle",
            render: (_, record) => (
                <Link to={`/edit-product/${orderData[0].itemID}`}>
                    <AvatarStatus
                        size={30}

                        src={orderData[0].itemImage}
                        name={orderData[0].itemTitle}
                    />
                </Link>
            ),
        },
        {
            title: "ID",
            dataIndex: "id",
            render: (_, record) => <Text>#{orderData[0].itemID}</Text>,
        },

        {
            title: "Barcode",
            dataIndex: "itemBarcode",
            render: (_, record) => (
                <div>
                    <span className="font-weight-semibold">
                        {orderData[0].itemBarcode}
                    </span>
                </div>
            ),
        },
        {
            title: "Condition",
            dataIndex: "itemCondition",
            render: (_, record) => (
                <div>
                    <span className="font-weight-semibold">
                        {orderData[0].itemCondition}
                    </span>
                </div>
            ),
        },
        {
            title: "Quantity",
            dataIndex: "orderQuantity",
            render: (_, record) => (
                <div>
                    <span className="font-weight-semibold">
                        {orderData[0].orderQuantity}
                    </span>
                </div>
            ),
        },
        {
            title: "Order Kind ",
            dataIndex: "orderKind ",
            render: (_, record) => (
                <div>
                    <span className="font-weight-semibold">
                        {orderData[0].orderKind}
                    </span>
                </div>

            ),


        },
        {
            title: "Price",
            dataIndex: "itemPrice",
            render: (_, record) => (
                <div>
                    <span className="font-weight-semibold">
                        <NumberFormat
                            displayType={"text"}
                            value={(
                                Math.round(orderData[0].orderItemPrice * 100) / 100
                            ).toFixed(2)}
                            prefix={"CAD$"}
                            thousandSeparator={true}
                        />
                    </span>
                </div>
            ),
        },

        {
            title: "Actions",
            dataIndex: "actions",
            render: (_, elm) => (
                <div className="text-left">
                    {/* {orderData[0].orderStatus == "Pending store approval" && ( */}
                        <div className="text-left">
                            {orderData[0].orderStatus === "Pending store approval" ? <Button
                                onClick={() => {
                                    acceptOrder(id);
                                }}
                                style={{
                                    backgroundColor: "green",
                                    color: "white",
                                    margin: 5
                                }}
                                size="small"
                            >
                                {orderData[0].orderKind === "Delivery" ? "Accept Delivery order" : 'Accept Pickup order'}
                            </Button> : null}
                            {orderData[0].orderStatus === "Pending store approval" ? <Button
                                style={{
                                    backgroundColor: "red",
                                    color: "white",
                                    margin: 5
                                }}
                                size="small"
                                onClick={() => {
                                    rejectOrder(id);
                                }}
                            >
                                {orderData[0].orderKind === "Delivery" ? "Reject Delivery order" : 'Reject Pickup order'}
                            </Button> : null}

                            {orderData[0].orderStatus === "Store Preparing Order" ?
                                <Button
                                    onClick={() => {
                                        acceptOrder(id);
                                    }}
                                    style={{
                                        backgroundColor: "green",
                                        color: "white",
                                        margin: 5
                                    }}
                                    size="small"
                                >
                                    Ready for delivery
                                </Button> : null}


                            {orderData[0].orderStatus === "Processing (Ready for Pickup)" ?
                                <Button
                                    onClick={() => {
                                        acceptOrder(id);
                                    }}
                                    style={{
                                        backgroundColor: "green",
                                        color: "white",
                                        margin: 5
                                    }}
                                    size="small"
                                >
                                    Ready for Pickup
                                </Button> : null}

                            {orderData[0].orderStatus === "Ready for Pickup" ?
                                <Button
                                    onClick={() => {
                                        OrderPickedup(id);
                                    }}
                                    style={{
                                        backgroundColor: "green",
                                        color: "white",
                                        margin: 5
                                    }}
                                    size="small"
                                >
                                    Picked-up
                                </Button> : null}



                        </div>
                    {/* )} */}
                </div>
            ),
        },
    ];

    const OrderPickedup = orderId => {




        app.firestore()
            .collection("orders")
            .doc(orderId)
            .update({
                orderStatus: `(Picked-up) ${new Date()}`,
                orderPickedupAt: new Date(),
            })
            .then(() => {
                return message.success("order accepted");
            });



    }
    const acceptOrder = orderId => {

        console.log({ orderData, orderId })

        if (orderData[0].orderKind === "Delivery") {
            app.firestore()
                .collection("orders")
                .doc(orderId)
                .update({
                    orderStatus: orderData[0].orderStatus === "Store Preparing Order" ? "Ready for delivery" : 'Store Preparing Order',
                    //    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                })
                .then(() => {
                    return message.success("order accepted");
                });
        }
        else {
            app.firestore()
                .collection("orders")
                .doc(orderId)
                .update({

                    orderStatus: orderData[0].orderStatus === "Processing (Ready for Pickup)" ? "Ready for Pickup" : 'Processing (Ready for Pickup)',
                    //    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                })
                .then(() => {
                    return message.success("order accepted");
                });
        }

    };
    const rejectOrder = orderId => {
        app.firestore()
            .collection("orders")
            .doc(orderId)
            .update({
                orderStatus: "Store refuse order",
                //    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            })
            .then(() => {
                return message.error("Order rejected");
            });
    };

    if (loading) {
        return "sdads";
    } else {
        return (
            <div className="container">
                <Card>
                    <div className="d-md-flex justify-content-md-between">
                        <div>
                            <div className="mt-3 text-left">
                                <h2 className="mb-1 font-weight-semibold">
                                    Status:
                                </h2>
                                <p>
                                    Order Status:{" "}
                                    <Text
                                        strong
                                        type={getShippingStatus(
                                            orderData[0].orderStatus
                                        )}
                                    >
                                        {orderData[0].orderStatus}
                                    </Text>
                                    <br />
                                    Payment Status:{" "}
                                    <Text
                                        strong
                                        type={getPaymentStatus(
                                            orderData[0].storeOrderPaymentStatus
                                        )}
                                    >
                                        {orderData[0].storeOrderPaymentStatus}
                                    </Text>
                                </p>
                            </div>
                        </div>
                        <div className="mt-3 text-left">
                            <h2 className="mb-1 font-weight-semibold">
                                Order #{id}
                            </h2>
                            <p>
                                Created at:{" "}
                                {moment(orderData[0].orderCreatedAt.toDate()).format(
                                    "MM/DD/YYYY"
                                )}
                                <br />
                                Updated at:{" "}
                                {moment(orderData[0].orderUpdatedAt.toDate()).format(
                                    "MM/DD/YYYY"
                                )}
                            </p>
                        </div>
                    </div>
                    <div className="mt-4">
                        <Table
                            dataSource={invoiceData}
                            columns={tableColumns}
                            pagination={false}
                            className="mb-5"
                        ></Table>
                        <div className="d-flex justify-content-end">
                            <div className="text-right ">
                                <div className="border-bottom">

                                    <p className="mb-2">
                                        <span>Order Quantity: </span>
                                        <Text strong>
                                            <NumberFormat
                                                displayType={"text"}
                                                value={(
                                                    Math.round(
                                                        orderData[0].orderQuantity * 100
                                                    ) / 100
                                                ).toFixed(2)}

                                                thousandSeparator={true}
                                            />
                                        </Text>
                                    </p>
                                    <p className="mb-2">
                                        <span>Product Price: </span>
                                        <Text strong>
                                            <NumberFormat
                                                displayType={"text"}
                                                value={(
                                                    Math.round(
                                                        orderData[0].p * 100
                                                    ) / 100
                                                ).toFixed(2)}
                                                prefix={"CAD$ "}
                                                thousandSeparator={true}
                                            />
                                        </Text>
                                    </p>

                                    <p className="mb-2">
                                        <span>Order Tax: </span>
                                        <Text strong>
                                            <NumberFormat
                                                displayType={"text"}
                                                value={(
                                                    Math.round(
                                                        orderData[0].orderTax * 100
                                                    ) / 100
                                                ).toFixed(2)}
                                                prefix={"CAD$ "}
                                                thousandSeparator={true}
                                            />
                                        </Text>
                                    </p>
                                    <p className="mb-2">
                                        <span>Sub-total: </span>
                                        <Text strong>
                                            <NumberFormat
                                                displayType={"text"}
                                                value={(
                                                    Math.round(
                                                        orderData[0]
                                                            .orderSubTotalPrice *
                                                        100
                                                    ) / 100
                                                ).toFixed(2)}
                                                prefix={"CAD$"}
                                                thousandSeparator={true}
                                            />
                                        </Text>
                                    </p>
                                    <p className="mb-2">
                                        <span>Order Service Fee: </span>
                                        <Text strong>
                                            <NumberFormat
                                                displayType={"text"}
                                                value={(
                                                    Math.round(
                                                        orderData[0].orderServiceFee * 100
                                                    ) / 100
                                                ).toFixed(2)}
                                                prefix={"CAD$ "}
                                                thousandSeparator={true}
                                            />
                                        </Text>
                                    </p>
                                    {/* <p className="mb-2">
                                        <span>Order Store Payment : </span>
                                        <Text strong>
                                            <NumberFormat
                                                displayType={"text"}
                                                value={(
                                                    Math.round(
                                                        orderData[0].orderSubTotalPrice  * 100
                                                    ) / 100
                                                    - Math.round(
                                                        orderData[0].orderServiceFee * 100
                                                    ) / 100

                                                ).toFixed(2)}
                                                prefix={"CAD$ "}
                                                thousandSeparator={true}
                                            />
                                        </Text>
                                    </p>
                              */}
                                    {/* <p> */}
                                    {/* Fees ({orderData[0].itemTax}) */}
                                    {/* <NumberFormat
                        displayType={"text"}
                        value=
                            {Math.round(
                        orderData[0].subtotalItemPrice *
                          (orderData[0].storeFees / 100)
                      ).toFixed(2)}
                        prefix={"CAD$"}
                        thousandSeparator={true}
                      /> */}
                                    {/* </p> */}
                                </div>
                                <h2 className="font-weight-semibold mt-3">
                                    <span className="mr-1">Order Store Payment: </span>
                                    {/* <NumberFormat
                                        displayType={"text"}
                                        value={orderData[0].orderStorePayment}
                                        prefix={"CAD$"}
                                        thousandSeparator={true}
                                    /> */}

                                    <NumberFormat
                                        displayType={"text"}
                                        value={(
                                            Math.round(
                                                orderData[0].orderSubTotalPrice * 100
                                            ) / 100
                                            - Math.round(
                                                orderData[0].orderServiceFee * 100
                                            ) / 100

                                        ).toFixed(2)}
                                        prefix={"CAD$ "}
                                        thousandSeparator={true}
                                    />
                                </h2>
                            </div>
                        </div>
                    </div>
                </Card>
                {orderData[0].orderStatus.toLowerCase().includes("return") ? (
                    <ReturnRequest orderData={orderData} id={id} />
                ) : null}
            </div>
        );
    }
};

export default OrderDetails;
