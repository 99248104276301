import React, {  useEffect } from "react";
import { Helmet } from 'react-helmet';
// firebase app
// import app from "../Utils/firebaseConfig";
// firebase app
import firebase from "firebase";
import "firebaseui/dist/firebaseui.css";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import {app} from '../../../auth/FirebaseAuth'
// useInput

// react toastify

// validator

// react router dom
import { useHistory, Link } from "react-router-dom";
const LoginForm = () => {
  // init useHistory
  const history = useHistory();
  // init uiconfig
  const uiConfig = {
    signInFlow: "popup",
    signInSuccessUrl: "/register",
    signInOptions: [firebase.auth.PhoneAuthProvider.PROVIDER_ID],
  };

  // init useInput

  // init btn Loading state
  useEffect(() => {
    app.auth().onAuthStateChanged((user) => {
      if (!user) {
      } else {
        // update userID state
        //check if the user filled the store form
        history.push('/register');

      }
    });
  }, []);

  

  return (
    <React.Fragment>
      <Helmet>
        <title>Welcome, Login</title>
        <link rel="stylesheet" href="/css/app-light.css" id="lightTheme" />
      </Helmet>
      <div class="wrapper vh-100 container">
        <div class="row align-items-center h-100">
          <div class="col-lg-4 d-none d-lg-flex">
            <img src="/img/storeCentral.svg" className="img-fluid" />
          </div>
          <div class="col-lg-8">
            <div class="w-100 mx-auto">
              <form class="mx-auto text-center" noValidate>
                <a
                  class="navbar-brand mx-auto mt-2 flex-fill text-center"
                  href="./index.html"
                >
                  {/* image */}
                  <h4>Seller Dashboard</h4>
                </a>
                <h1 class="h6 mb-3">Sign in</h1>

                <StyledFirebaseAuth
                  uiConfig={uiConfig}
                  firebaseAuth={app.auth()}
                />

                <Link
                  to="/register"
                  class="btn btn-lg btn-primary btn-block mt-4"
                  style={{
                    backgroundColor: "#fcfcfc",
                    color: "#212529",
                    borderColor: "#fcfcfc",
                  }}
                >
                  Create New Store
                </Link>

                <div className="mt-3">
                  <p>
                    <a
                      href="#"
                      style={{
                        color: "#dee2e6",
                      }}
                    >
                      Go to Shopping Website
                    </a>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LoginForm;
