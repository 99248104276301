import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import firebaseConfig from 'configs/FirebaseConfig';

const app = firebase.initializeApp(firebaseConfig);
 const settings = { timestampsInSnapshots: true };
app.firestore().settings(settings)
// firebase utils
const db = app.firestore();
const auth = app.auth();
const currentUser = app.auth().currentUser
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
 app.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
// app.analytics();
export {
	app,
	db,
	auth,
	currentUser,
	googleAuthProvider,
	facebookAuthProvider,
	twitterAuthProvider,
	githubAuthProvider
};