import React, { lazy, Suspense,} from "react";
import { Switch, Route} from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import Products from './products/index'
import AddProduct from "./products/addProduct";
import EditProduct from "./products/editProduct";
import Orders from "./orders/index";
import OrderDetails from './orders/OrderDetails';
import AccountSettings from './account/AccountSettings';
import Error from './error/index'
import { Helmet } from "react-helmet";
export const AppViews = (props) => {
 


  


  
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Helmet>
        <title>Aspect store</title>
      </Helmet>
      <Switch>
        <Route exact path={`/home`} component={lazy(() => import(`./home`))} />
        {/* <Redirect from={``} to={`/home`} /> */}
        <Route
          exact
          path={`/products`}
          component={() => (
            <Products userId={props.authUser} userData={props.userData} />
          )}
        />
        <Route
          exact
          path={`/add-product`}
          component={() => <AddProduct userId={props.authUser} />}
        />
        <Route
          exact
          path={`/edit-product/:id`}
          component={() => <EditProduct userId={props.authUser} />}
        />
        <Route
          exact
          path={`/orders`}
          component={() => (
            <Orders userId={props.authUser} userData={props.userData} />
          )}
        />
        <Route
          exact
          path={`/orders/:id`}
          component={() => (
            <OrderDetails userId={props.authUser} userData={props.userData} />
          )}
        />
        <Route
          exact
          path={`/account-settings`}
          component={() => (
            <AccountSettings
              userId={props.authUser}
              userData={props.userData}
            />
          )}
        />
        <Route
          exact
          path={`/error`}
          component={() => (
            <Error
            
            />
          )}
        />
        {/* <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/products`} /> */}
      </Switch>
    </Suspense>
  );
}

export default React.memo(AppViews);