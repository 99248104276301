import React from "react";
import {
  Input,
  Row,
  Col,
  Card,
  Form,
  InputNumber,
  message,
  Select,
} from "antd";
import MultiImageInput from "react-multiple-image-input";


const { Option } = Select;

const rules = {
  name: [
    {
      required: true,
      message: "Please enter product name",
    },
  ],
  description: [
    {
      required: true,
      message: "Please enter product description",
    },
  ],
  condition: [
    {
      required: true,
      message: "Please select product condition",
    },
  ],
  category: [
    {
      required: true,
      message: "Please select product category",
    },
  ],
  price: [
    {
      required: true,
      message: "Please enter product price",
    },

  
  
  ],
  itemTax:[
    {
      required: true,
      message: "Please enter product Item Tax",
    },
  ],
  quantity: [
    {
      required: true,
      message: "Please enter product quantity",
    },
  ],
  barcode: [
    {
      required: true,
      message: "Please enter product barcode",
    },
  ],
 
};



const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  // const isLt2M = file.size / 1024 / 1024 < 2;
  // if (!isLt2M) {
  //   message.error("Image must smaller than 2MB!");
  // }
  return isJpgOrPng ;
};


const status = ["Active", "Inactive"];

const GeneralField = (props) => {

    return (
      <Row gutter={16}>
        <Col xs={24} sm={24} md={17}>
          <Card title="Basic Info">
            <Form.Item name="name" label="Product name" rules={rules.name}>
              <Input placeholder="Product Name" />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              rules={rules.description}
            >
              <Input.TextArea rows={4} />
            </Form.Item>
          </Card>
          <Card title="Pricing">
          <Row gutter={16}>
          <Col xs={24} sm={24} md={12}>
            <Form.Item name="price" label="Price" rules={rules.price}>
              <InputNumber className="w-100" placeholder={"3.99 CAD$"} />
            </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
            <Form.Item name="itemTax" label="Item Tax" rules={rules.itemTax}>
              <InputNumber  className="w-100" placeholder={"3%"} />
            </Form.Item>
            </Col>
            </Row>
            {props.isEdit === "EDIT" && (
              <Form.Item
                name="discountedPrice"
                label="Discounted Price"
                // rules={rules.price}
              >
                <InputNumber className="w-100" placeholder={"3.99 CAD$"} />
              </Form.Item>
            )}

            <Row gutter={16}>
              <Col xs={24} sm={24} md={12}>
                <Form.Item name="barcode" label="Barcode" rules={rules.barcode}>
                  <InputNumber className="w-100" value="" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="quantity"
                  label="Quantity"
                  rules={rules.quantity}
                >
                  <InputNumber className="w-100" value={1} />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={7}>
          <Card title="Organization">
            <Form.Item name="category" label="Category" rules={rules.category}>
              <Select className="w-100" placeholder="Category">
                {props.categories.map((elm) => (
                  <Option key={elm.value} value={elm.value}>
                    {elm.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="Condition"
              label="Condition"
              rules={rules.condition}
            >
              <Select
                mode="Condition"
                style={{ width: "100%" }}
                placeholder="Condition"
              >
                {props.conditions.map((elm) => (
                  <Option key={elm.value} value={elm.value}>
                    {elm.value}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            {props.isEdit == "EDIT" && (
              <div>
                {!props.showStatusSelect && (
                  <h5 style={{ padding: 0, margin: 9 }}>
                    Status:{props.status}
                  </h5>
                )}

                {props.showStatusSelect && (
                  <Form.Item
                    name="status"
                    label="status"
                    rules={rules.condition}
                  >
                    <Select
                      mode="status"
                      style={{ width: "100%" }}
                      placeholder="status"
                    >
                      {status.map((elm) => (
                        <Option key={elm}>{elm}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </div>
            )}
          </Card>
          <Card title="Media">
            <MultiImageInput
              images={props.images}
              setImages={props.handleImages}
              max={5}
              allowCrop={false}
            />
            {/* <Dragger
              {...imageUploadProps}
              beforeUpload={beforeUpload}
              onChange={(e) => props.handleUploadChange(e)}
            >
              {props.uploadedImg ? (
                <img
                  src={props.uploadedImg}
                  alt="avatar"
                  className="img-fluid"
                />
              ) : (
                <div>
                  {props.uploadLoading ? (
                    <div>
                      <LoadingOutlined className="font-size-xxl text-primary" />
                      <div className="mt-3">Uploading</div>
                    </div>
                  ) : (
                    <div>
                      <CustomIcon className="display-3" svg={ImageSvg} />
                      <p>Click or drag file to upload</p>
                    </div>
                  )}
                </div>
              )}
            </Dragger> */}
          </Card>
        </Col>
      </Row>
    );
}
export default GeneralField;
