import React, { useState,useEffect } from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { connect } from 'react-redux'
import { 
  EditOutlined, 
  SettingOutlined, 
  ShopOutlined, 
  QuestionCircleOutlined, 
  LogoutOutlined 
} from '@ant-design/icons';

import Icon from 'components/util-components/Icon';
import {app} from '../../auth/FirebaseAuth'
import { useHistory } from "react-router";
const menuItem = [

    
    {
		title: "Account Setting",
		icon: SettingOutlined,
    },
   
  
]

export const NavProfile = (props) => {
  let history = useHistory();
  
  // useEffect(() => {
      
  //   app.firestore().collection('stores').where('mobileNumber', '==', app.auth().currentUser.phoneNumber).get().then((query) => {
  //     query.forEach((doc) => {
  //        setData({...doc.data()})
  //      })
  //    })
    
  //   }, []);
  const profileImg = props.userData.storeLogo;

 
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={45} src={profileImg} />
          <div className="pl-3">
            <h4 className="mb-0">{props.userData.storeName}</h4>
            <span className="text-muted">{props.userData.storeAccountStatus}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i} isSelected={false} onClick={() => {
                history.push('/account-settings')
              }}>
                  <Icon className="mr-3" type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
              </Menu.Item>
            );
          })}
          <Menu.Item
            key={menuItem.length + 1}
            onClick={(e) => {
              app.auth().signOut();
            }}
          >
            <span>
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item>
          <Avatar src={profileImg} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
}

export default NavProfile
