/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Card,
  Table,
  Select,
  Input,
  Button,
  Badge,
  Tag,
  message,
  Tabs,
} from "antd";
import firebase from 'firebase';
import {
  SearchOutlined,
} from "@ant-design/icons";
import AvatarStatus from "components/shared-components/AvatarStatus";
import Flex from "components/shared-components/Flex";
import NumberFormat from "react-number-format";
import moment from "moment";
import utils from "utils";
import FuzzySearch from "fuzzy-search";
import { app } from '../../../auth/FirebaseAuth';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
const { Option } = Select;
const { TabPane } = Tabs;

const getPaymentStatus = (status) => {
  if (status === "Paid") {
    return "success";
  }
  if (status === "Hold") {
    return "warning";
  }
  if (status === "Canceled" || status === "Refunded") {
    return "error";
  }
  return "";
};

const getShippingStatus = (status) => {
  if (
    status === "Out for delivery" ||
    status === "Processing" ||
    status === "Pending store approval"
  ) {
    return "blue";
  }

  if (status === "Delivered") {
    return "cyan";
  }
  if (status === "store Refusal" || status === "Store Refusal") {
    return "red";
  }
  return "";
};




const Orders = (props) => {
  const [list, setList] = useState([]);
  const [OrderListData, setOrdersDataList] = useState([]);
  const [currentOrders, setCurrentOrders] = useState([]);
  const [mainCurrentOrders, setMainCurrentOrders] = useState([]);
  const [historyOrders, setHistoryOrders] = useState([]);
  const [mainHistoryOrders, setMainHistoryOrders] = useState([]);
  const searchOrders = new FuzzySearch(OrderListData, ["itemTitle"]);
  const [orderStatusList, setOrderStatusList] = useState([]);
  const [paymentStatusList, setPaymentStatusList] = useState([]);
  const [hide, setHide] = useState(window.innerWidth <= 600);
  const listenToMedia = (e) => {
    if (e.matches) {
      /* the viewport is 600 pixels wide or less */
      setHide(true);
    }
    else {
      setHide(false);
    }
  }
  useEffect(async () => {
    // You need to restrict it at some point
    // This is just dummy code and should be replaced by actual

    if (props.userId != undefined) {
      var mql = window.matchMedia("(max-width: 600px)");

      mql.addEventListener("change", listenToMedia);
      let realtime = null
      try {
        let orderStatus = await app
          .firestore()
          .collection("settings")
          .doc("orderStatus")
          .get();
        setOrderStatusList(orderStatus.data().options);
        let paymentStatus = await app
          .firestore()
          .collection("settings")
          .doc("paymentStatus")
          .get();
        setPaymentStatusList(paymentStatus.data().options);
        realtime = app
          .firestore()
          .collection("orders")
          // .where("storeID", "==", "EyiSi5BVSyx2LRrrO7xY")
           .where("storeID", "==", props.userId)

          .onSnapshot((query) => {
            let list = [];

            query.forEach((doc) => {
              console.log({ data: doc.data(), id: doc.id, doc })
              list.push({ ...doc.data(), id: doc.id });
            });
            list.sort((a, b) => b.orderCreatedAt.toDate() - a.orderCreatedAt.toDate());
            //  setList(list);
            setOrdersDataList(list);
            handleHistoryOrder("current", list);
            handleHistoryOrder("history", list);
          });
      } catch (error) {
        message.error(error)
      }

      return () => {
        mql.removeEventListener("change", listenToMedia);
        if (realtime != null) {
          realtime();

        }
      };
    }
  }, []);
  const handleShowOrderStatus = (value) => {
    if (value !== "All") {
      const key = "orderStatus";
      const historyData = utils.filterArray(mainHistoryOrders, key, value);
      const currentData = utils.filterArray(mainCurrentOrders, key, value);
      setHistoryOrders(historyData);
      setCurrentOrders(currentData);
    } else {
      setHistoryOrders(mainHistoryOrders);
      setCurrentOrders(mainCurrentOrders);
    }
  };

  const handleShowStatus = (value) => {
    if (value !== "All") {
      // const key = "paymentStatus";
      const key = 'storeOrderPaymentStatus'
      const historyData = utils.filterArray(mainHistoryOrders, key, value);
      const currentData = utils.filterArray(mainCurrentOrders, key, value);
      setHistoryOrders(historyData);
      setCurrentOrders(currentData);
    } else {
      setHistoryOrders(mainHistoryOrders);
      setCurrentOrders(mainCurrentOrders);
    }
  };

  const tableColumns = [
    {
      title: "Product",
      dataIndex: "name",
      render: (_, record) => (
        <Link to={`/orders/${record.id}`}>
          <div className="d-flex">
            <AvatarStatus
              size={30}
              src={record.itemImage}
              name={record.itemTitle}
            />
          </div>
          {hide === true && (
            <>
              <Tag
                id="s"
                style={{ marginTop: 10 }}
                color={getShippingStatus(record.orderStatus)}
              >
                {record.orderStatus}
              </Tag>

              <div
                className=" action"
                style={{ marginTop: 10 }}
              >
                {record.orderStatus == "Pending store approval" && (
                  <div>
                    <Button
                      onClick={() => {
                        acceptOrder(record.id, record.orderKind, record.orderStatus);
                      }}
                      style={{ backgroundColor: "green", color: "white", margin: 10 }}
                      size="small"
                    >
                      Acceptsss
                    </Button>

                    <Button
                      style={{ backgroundColor: "red", color: "white", margin: 10 }}
                      size="small"
                      onClick={() => {
                        rejectOrder(record.id);
                      }}
                    >
                      Reject
                    </Button>
                  </div>
                )}

              </div>
            </>
          )
          }
        </Link >
      ),
      sorter: (a, b) => utils.antdTableSorter(a, b, "name"),
    },
    {
      title: "ID",
      dataIndex: "id",
      render: (_, record) => (
        <Link to={`/orders/${record.id}`}>
          <span>#{record.id}</span>
        </Link>
      ),
    },

    {
      title: "Date",
      dataIndex: "orderCreatedAt",
      render: (_, record) => (
        <span>{moment(record.orderCreatedAt.toDate()).format("MM/DD/YYYY")}</span>
      ),
      sorter: (a, b) => utils.antdTableSorter(a, b, "orderCreatedAt"),
    },
    {
      title: "Price",
      dataIndex: "amount",
      render: (_, record) => (
        <div>
          <span className="font-weight-semibold">
            Sub-total:{" "}
            <NumberFormat
              displayType={"text"}
              value={(Math.round(record.subtotalItemPrice * 100) / 100).toFixed(
                2
              )}
              prefix={"$"}
              thousandSeparator={true}
            />
          </span>
          <br />

          <span className="font-weight-semibold">
            Store total:{" "}
            <NumberFormat
              displayType={"text"}
              value={(Math.round(record.storePayment * 100) / 100).toFixed(2)}
              prefix={"$"}
              thousandSeparator={true}
            />
          </span>
        </div>
      ),
      sorter: (a, b) => utils.antdTableSorter(a, b, "amount"),
    },

    {
      title: "Payment status",
      dataIndex: "storeOrderPaymentStatus",
      render: (_, record) => (
        <>
          <Badge status={getPaymentStatus(record.storeOrderPaymentStatus)} />
          <span>{record.storeOrderPaymentStatus}</span>
        </>
      ),
      sorter: (a, b) => utils.antdTableSorter(a, b, "storeOrderPaymentStatus"),
    },
    {
      title: "Order Kind ",
      dataIndex: "orderKind ",
      render: (_, record) => (
        <span>{record.orderKind}</span>
      ),
      sorter: (a, b) => utils.antdTableSorter(a, b, "orderKind"),

    },
    hide === false
      ? {
        title: "Order status",
        dataIndex: "orderStatus",
        render: (_, record) => {
          return (
            <>
              <Tag id="s" color={getShippingStatus(record.orderStatus)}>
                {record.orderStatus}
              </Tag>
            </>
          );
        },
        sorter: (a, b) => utils.antdTableSorter(a, b, "orderStatus"),
      }
      : {},
    hide === false
      ? {
        title: "",
        dataIndex: "actions",
        render: (_, elm) => (
          <div className="text-right action" id="action">
            {elm.orderStatus === "Pending store approval" ?

              <Button
                onClick={() => {
                  acceptOrder(elm.id, elm.orderKind, elm.orderStatus);
                }}
                style={{ backgroundColor: "green", color: "white", margin: 5 }}
                size="small"
              >
                {elm.orderKind === "Delivery" ? "Accept Delivery order" : 'Accept Pickup order'}
              </Button>
              : null

            }
            {elm.orderStatus === "Pending store approval" ?

              <Button
                style={{ backgroundColor: "red", color: "white", margin: 5 }}
                size="small"
                onClick={() => {
                  rejectOrder(elm.id);
                }}
              >
                {elm.orderKind === "Pickup" ? "Reject  Pickup order" : 'Reject delivery order'}
              </Button>
              : null

            }
            {elm.orderStatus === "Store Preparing Order"   && elm.orderKind  ==="Delivery"?
              <Button
                onClick={() => {
                  acceptOrder(elm.id, elm.orderKind, elm.orderStatus);
                }}
                style={{
                  backgroundColor: "green",
                  color: "white",
                  margin: 5
                }}
                size="small"
              >
                Ready for delivery
              </Button> : null}


            {elm.orderStatus === "Store Preparing Order"  && elm.orderKind  ==="Pickup"?
              <Button
                onClick={() => {
                  acceptOrder(elm.id, elm.orderKind, elm.orderStatus);
                }}
                style={{
                  backgroundColor: "green",
                  color: "white",
                  margin: 5
                }}
                size="small"
              >
                Ready for Pickup
              </Button> : null}

            {elm.orderStatus === "Ready for pickup" ?
              <Button
                onClick={() => {
                  OrderPickedup(elm.id, elm.orderKind, elm.orderStatus);
                }}
                style={{
                  backgroundColor: "green",
                  color: "white",
                  margin: 5
                }}
                size="small"
              >
                Picked-up
              </Button> : null}
          </div>
        ),
      }
      : {},
  ];
  const OrderPickedup = (orderId, ) => {




    app.firestore()
        .collection("orders")
        .doc(orderId)
        .update({
            orderStatus: `Picked-up`,
            orderPickedupAt: new Date(),
        })
        .then(() => {
            return message.success("order accepted");
        });



}
  const acceptOrder = (orderId, orderKind, orderStatus) => {



    if (orderKind === "Delivery") {
      app.firestore()
        .collection("orders")
        .doc(orderId)
        .update({
          orderStatus: orderStatus === "Store Preparing Order" ? "Ready for delivery" : 'Store Preparing Order',
          //    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(() => {
          return message.success("order accepted");
        });
    }
    else {
      app.firestore()
        .collection("orders")
        .doc(orderId)
        .update({

          orderStatus: orderStatus === "Store Preparing Order" ? "Ready for pickup" : 'Store Preparing Order',
          //    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(() => {
          return message.success("order accepted");
        });
    }



  };
  const rejectOrder = (orderId) => {
    app
      .firestore()
      .collection("orders")
      .doc(orderId)
      .update({
        orderStatus: "Store refuse order",
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      }).then(() => {
        return message.error("Order rejected");

      });
  };


  const onSearch = (e) => {
    const value = e.currentTarget.value;
    const searchArray = e.currentTarget.value ? list : OrderListData;
    const data = utils.wildCardSearch(searchArray, value);
    const result = searchOrders.search(value);

    setList(result);
  };
  const handleHistoryOrder = (value, li) => {
    console.log({ value, li })

    let now = moment().startOf("day");
    let start = moment().clone().startOf("day");
    let end = moment().clone().endOf("day");
    let filteredArr = [];
    switch (value) {
      case "current":

        filteredArr = utils.filterArrayByDateRange(
          li,
          "orderCreatedAt",
          start,
          end
        );
        const data = li.filter(
          (item) =>
            item["orderStatus"] === "Pending store approval" &&
            !(
              start < item["orderCreatedAt"].toDate() &&
              item["orderCreatedAt"].toDate() < end
            )
        );
        data.forEach((ite) => {
          filteredArr.push(ite);
        })
        setMainCurrentOrders(filteredArr);
        setCurrentOrders(filteredArr);
        break;
      case "history":
        const historyOrders = li.filter(
          (item) =>

            !(
              start < item["orderCreatedAt"].toDate() &&
              item["orderCreatedAt"].toDate() < end
            )
        );
        setMainHistoryOrders(historyOrders);
        setHistoryOrders(historyOrders);
        break;

      default:
        setList(li);
        return;
    }





    return;
  }

  return (
    <>
      <Helmet>
        <style></style>
      </Helmet>
      <Card>
        <Flex alignItems="center" justifyContent="between" mobileFlex={false}>
          <Flex className="mb-1" mobileFlex={false}>
            {/* <div className="mr-md-3 mb-3">
              <Input
                placeholder="Search by product title"
                prefix={<SearchOutlined />}
                onChange={(e) => onSearch(e)}
              />
            </div> */}
            <div className="mb-3">
              <Select
                defaultValue="All"
                className="w-100"
                style={{ minWidth: 180 }}
                onChange={handleShowOrderStatus}
                placeholder="Status"
              >
                <Option value="All">All order status </Option>
                {orderStatusList.map((elm) => (
                  <Option key={elm.value} value={elm.value}>
                    {elm.label}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="mb-3">
              <Select
                defaultValue="All"
                className="w-100"
                style={{ minWidth: 180 }}
                onChange={handleShowStatus}
                placeholder="Status"
              >
                <Option value="All">All payment </Option>
                {paymentStatusList.map((elm) => (
                  <Option key={elm.value} value={elm.value}>
                    {elm.label}
                  </Option>
                ))}
              </Select>
            </div>
            {/* <div className="mb-3">
              <Select
                defaultValue="All"
                className="w-100"
                style={{ minWidth: 180 }}
                onChange={handleHistoryOrder}
                placeholder="Status"
              >
                <Option value="all">All Orders</Option>
                <Option value="current">Current Orders </Option>
                <Option value="history">History Orders</Option>
              </Select>
            </div> */}

            {/* <div className="mb-3">
            <Select
              defaultValue="All"
              className="w-100"
              style={{ minWidth: 180 }}
              onChange={handleShowCurrentOrders}
              placeholder="Status"
            >
              <Option value="All">All Orders </Option>
              <Option key="elm" value="current">
                Current Orders
              </Option>
              <Option key="el1m" value="history">
                History Orders
              </Option>
            </Select>
          </div> */}
          </Flex>
        </Flex>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Current Orders" key="1">
            <div className="table-responsive">
              <Table columns={tableColumns} dataSource={currentOrders} rowKey="id" />
            </div>
          </TabPane>
          <TabPane tab="History Orders" key="2">
            <div className="table-responsive">
              <Table columns={tableColumns} dataSource={historyOrders} rowKey="id" />
            </div>
          </TabPane>

        </Tabs>
      </Card>
    </>
  );
};

export default Orders;
