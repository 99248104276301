import React from 'react'
import { APP_NAME } from 'configs/AppConfig';

export default function Footer() {

	var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
// var today  = new Date();

// console.log(today.toLocaleDateString("en-US")); // 9/17/2016
// console.log(today.toLocaleDateString("en-US", options)); 
	return (
		<footer className="footer" style={{display:'flex', justifyContent:'flex-end', alignItems:'flex-end'}}>
			{/* <span>Copyright  &copy;  {`${new Date().getFullYear()}`} <span className="font-weight-semibold">{`${APP_NAME}`}</span> All rights reserved.</span> */}
		<h5>{new Date().toISOString().split('T')[0]} {   new Date().toLocaleTimeString()}</h5>
		
		</footer>
	)
}

