import React, { useEffect, useState } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "layouts/app-layout";
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from 'antd';
import useBodyClass from 'hooks/useBodyClass';
import LoginForm from './auth-views/components/LoginForm';
import RegisterForm from './auth-views/components/RegisterForm';
import { app } from '../auth/FirebaseAuth'
import loaderGif from '../assets/svg/Hourglass.gif'
import loaderGif2 from '../assets/svg/SpinningArrows.gif'
export const Views = (props) => {


  const [loading, setLoading] = useState(true)
  const [initialScreen, setInitialScreen] = useState("")

  useEffect(() => {
    setLoading(true)
    app.auth().onAuthStateChanged((user) => {
      if (!user) {
        setLoading(false)
        setInitialScreen("login")
      } else {
        // update userID state
        //check if the user filled the store form


        app
          .firestore()
          .collection("stores")
          .where("storeMobile", "==", user.phoneNumber)
          .get()
          .then((snap) => {
            //user did not fill the form stay here
            if (snap.empty) {
              setLoading(false)
              setInitialScreen("register")

            }
            else {
              setLoading(false)
              setInitialScreen("home")

            }
          })

      }
    });
  }, []);
  window.gtag("config", "G-TLLTEW6R69", {
    page_title: props.location.pathname,
    page_path: props.location.pathname,
  });
  const { locale, location, direction } = props;
  const currentAppLocale = AppLocale[locale];
  useBodyClass(`dir-${direction}`);
  return (

    <>
      {loading ?
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
          backgroundColor: '#FFF',
          height: window.innerHeight
        }} >
          <img style={{height:50, width:50}} src={loaderGif} />


        </div> :
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}
        >
          <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
            <Switch>
              {initialScreen === "login" ?
                <Route exact path="/">
                  <LoginForm />
                </Route>

                
                :
                initialScreen === "register" ?
                  <Route exact path="/register">
                    <RegisterForm />
                  </Route>
                  :
                  <Route>
                    <AppLayout direction={direction} location={location} />
                  </Route>}

                  <Route exact path="/register">
                    <RegisterForm />
                  </Route>
            </Switch>
          </ConfigProvider>
        </IntlProvider>
      }
    </>
  );
}

const mapStateToProps = ({ theme, auth }) => {
  const { locale, direction } = theme;
  const { token } = auth;
  return { locale, token, direction }
};

export default withRouter(connect(mapStateToProps)(Views));