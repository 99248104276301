import React, { useState, useEffect } from "react";
import PageHeaderAlt from "components/layout-components/PageHeaderAlt";
import { Tabs, Form, Button, message } from "antd";
import Flex from "components/shared-components/Flex";
import GeneralField from "./GeneralField";
import firebase from "firebase";
import { app } from "../../../auth/FirebaseAuth";
import { useHistory, useParams } from "react-router-dom";
var _ = require("lodash");

const { TabPane } = Tabs;


const ADD = "ADD";
const EDIT = "EDIT";

const ProductForm = (props) => {

  let history = useHistory()
  const { mode = ADD, param } = props;
  const [images, setImages] = useState({});
  const [imagesUrl, setImagesUrl] = useState([]);
  const [showStatusSelect, setShowStatusSelect] = useState(false)
  const [itemStatus, setItemStatus] = useState('');
  const [form] = Form.useForm();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [conditions, setConditions] = useState([]);
  const [initialValues, setInitialValues] = useState({});

  let productID = useParams();
  useEffect(async () => {
    let cats = await app.firestore().collection("settings").doc("categories").get();
    let conds = await app.firestore().collection("settings").doc("conditions").get();
    setConditions(conds.data().options);
    setCategories(cats.data().options);


    if (mode === EDIT) {
      let id = productID.id;


      // let id = "I8764364454148"

      const listner = app.firestore().collection('items').doc(id).onSnapshot((doc) => {
        let data = doc.data();
        console.log({ data })
        setInitialValues({
          itemBarcode: data.itemBarcode,
          itemQuantity: data.itemQuantity,
          itemCondition: data.itemCondition,
          itemDescription: data.itemDescription,
          itemTax:data.itemTax,
          itemCategory: data.itemCategory,
          itemTitle: data.itemTitle,
          itemOriginalPrice: data.itemOriginalPrice,
          // status: data.status,
          itemDiscountedPrice: data.itemDiscountedPrice,
          itemImagesUrl: data.itemImagesUrl,
          storeID: props.userId,
        });
        form.setFieldsValue({
          barcode: data.itemBarcode,
          quantity: data.itemQuantity,
          Condition: data.itemCondition,
          description: data.itemDescription,
          itemTax:data.itemTax,
          category: data.itemCategory,
          name: data.itemTitle,
          price: data.itemOriginalPrice,
          itemStatus: data.itemStatus,
          discountedPrice: data.itemDiscountedPrice,
        });
        switch (data.status) {
          case "Active":
            setShowStatusSelect(true);
            setItemStatus("Active");
            break;
          case "Inactive":
            setShowStatusSelect(true);
            setItemStatus("Inactive");
            break;
          case "Pending":
            setItemStatus("Pending");
            break;

          default:
            setItemStatus("Unapproved");

            break;
        }
        setImages(Object.assign({}, data.itemImagesUrl));

        setImagesUrl(data.itemImagesUrl);
      });


      return () => listner()
    }
  }, []);



  const onFinish = async () => {
    setSubmitLoading(true);

    form
      .validateFields()
      .then(async (values) => {
        if (mode === ADD) {
          if (images === "") {
            message.error("Please select product image");
            setSubmitLoading(false);
            return;
          }
          let urls = [];
          let imagesArray = [];
          Object.keys(images).map((item, index) => {
            imagesArray.push(images[item]);
          });
          for (let index = 0; index < imagesArray.length; index++) {
            let res = await app
              .storage()
              .ref("/Items/" + Date.now())
              .putString(imagesArray[index], "data_url");
            let url = await res.ref.getDownloadURL();
            urls.push(url);
          }
          const addProductData = {
            itemTitle: values.name,
            itemOriginalPrice: Number(values.price),
            itemQuantity: Number(values.quantity),
            itemBarcode: values.barcode,
            itemCondition: values.Condition,
            itemCategory: values.category,
            itemDescription: values.description,
            itemTax:values.itemTax+"%",
            itemImagesUrl: urls,
            itemDiscountedPrice: 0,
            itemStatus: "Pending",
            storeID: props.userId,
            itemCreatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            itemUpdatedAt: firebase.firestore.FieldValue.serverTimestamp(),

          };
       
          app
            .firestore()
            .collection("items")
            .doc(generateRandomId())
            .set(addProductData)
            .then(() => {
              message.success(`Created ${values.name} to product list`);
              history.push("/products");
            })
            .catch((e) => {
              message.error(e);
            });;
        }
        if (mode === EDIT || false) {
          // spread images object
          const _images = { ...images };

          const _imagesUrl = [...imagesUrl];

          // convert images to array
          const _imagesArray = Object.values(_images);
          // differeence
          const imageIntersection = _imagesArray.filter(
            (x) => !_imagesUrl.includes(x)
          );
          let test = _images;
          if (imageIntersection.length != 0) {
            for (let index = 0; index < imageIntersection.length; index++) {
              let res = await app
                .storage()

                .ref("/Items/" + Date.now())

                .putString(imageIntersection[index], "data_url");

              let url = await res.ref.getDownloadURL();
              const base64ImgIndex = _imagesArray.indexOf(
                imageIntersection[index]
              );
              _imagesArray.push(url);
              _imagesArray.splice(base64ImgIndex, 1);
            }
          }
          let x = "Pending";
          let messa = "Product is now under review";
          const editProductData = {
            itemTitle: values.name,
            itemOriginalPrice: Number(values.price),
            itemQuantity: Number(values.quantity),
            itemBarcode: values.barcode,
            itemCondition: values.Condition,
            itemCategory: values.category,
            itemDescription: values.description,
            itemTax:values.itemTax+"%",
            itemImagesUrl: _imagesArray,
            itemDiscountedPrice: values.discountedPrice,
            // status: values.status,
            storeID: props.userId,
          };
          // console.log('formdata ', editProductData, ' init data ', initialValues);
          if (_.isEqual(editProductData, initialValues)) {
            x = values.status == undefined ? "Pending" : values.status;

            messa = "Product saved";
          }






          editProductData["itemCreatedAt"] = firebase.firestore.FieldValue.serverTimestamp();

          editProductData["itemUpdatedAt"] = firebase.firestore.FieldValue.serverTimestamp();

          editProductData["itemStatus"] = x;

          app
            .firestore()
            .collection("items")
            .doc(productID.id)
            .update(editProductData)
            .then(() => {
              message.success(messa);
              history.push("/products");
            })
            .catch((e) => {
              message.error(e);
            });
        }
      })
      .catch((info) => {
        setSubmitLoading(false);
        message.error("Please enter all required field ");
      });
  };
  const generateRandomId = () => {
    let id = "I";
    id += Math.floor(100000 + Math.random() * 9000000000000);

    return id;
  };
  return (
    <>
      <Form
        layout="vertical"
        form={form}
        name="advanced_search"
        className="ant-advanced-search-form"
      >
        <PageHeaderAlt className="border-bottom" overlap>
          <div className="container">
            <Flex
              className="py-2"
              mobileFlex={false}
              justifyContent="between"
              alignItems="center"
            >
              <h2 className="mb-3">
                {mode === "ADD" ? "Add New Product" : `Edit Product`}{" "}
              </h2>
              <div className="mb-3">
                <Button className="mr-2">Discard</Button>
                <Button
                  type="primary"
                  onClick={() => onFinish()}
                  htmlType="submit"
                  loading={submitLoading}
                >
                  {mode === "ADD" ? "Add" : `Save`}
                </Button>
              </div>
            </Flex>
          </div>
        </PageHeaderAlt>
        <div className="container">
          <Tabs defaultActiveKey="1" style={{ marginTop: 30 }}>
            <TabPane tab="General" key="1">
              <GeneralField
                images={images}
                handleImages={setImages}
                showStatusSelect={showStatusSelect}
                status={itemStatus}
                isEdit={mode}
                categories={categories}
                conditions={conditions}
              />
            </TabPane>
          </Tabs>
        </div>
      </Form>
    </>
  );
};

export default ProductForm;
