import React, { Suspense } from 'react';
import { Provider } from 'react-redux';

import loaderGif from './assets/svg/Hourglass.gif'
import { HashRouter } from 'react-router-dom';
import store from './redux/store';
import { BrowserRouter as Router } from 'react-router-dom';
import Views from './views';
import { Route, Switch } from 'react-router-dom';
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { THEME_CONFIG } from './configs/AppConfig';

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {

  return (
   
      <Provider store={store}>
        <HashRouter>
          <Suspense fallback={
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flex: 1,
              backgroundColor: '#FFF',
              height: window.innerHeight
            }} >
              <img style={{ height: 50, width: 50 }} src={loaderGif} />


            </div>
          }>
            <ThemeSwitcherProvider themeMap={themes} defaultTheme={THEME_CONFIG.currentTheme} insertionPoint="styles-insertion-point">
              <Router>
                <Switch>
                  <Route path="/" component={Views} />
                </Switch>
              </Router>
            </ThemeSwitcherProvider>
          </Suspense>
        </HashRouter>
      </Provider>
   
  );
}

export default App;
