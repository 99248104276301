import React, { Component, useState, useEffect, useCallback } from "react";
import {
  Row,
  Col,
  Card,
  Avatar,
  Button,
  Modal,
  Input,
  Typography,
  message,
  TimePicker,

} from "antd";
import moment from 'moment';
import { app } from '../../../auth/FirebaseAuth'

import AvatarStatus from "components/shared-components/AvatarStatus";
import PageHeaderAlt from "components/layout-components/PageHeaderAlt";
import Flex from "components/shared-components/Flex";
import isEmail from "validator/lib/isEmail";

const { RangePicker } = TimePicker;

const { Text } = Typography;

const ProfilePic = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [formData, setFormData] = useState({

    storeEmail: "",
    storeInstruction: "",

  });

  useEffect(() => {
    setFormData({

      storeEmail: props.userData.storeEmail,
      storeInstruction: props.userData.storeInstruction,

    });

  }, []);



  // init handleChange func
  const handleChange = useCallback((data) => (e) => {
    setFormData({
      ...formData,
      [data]: e.target.value,
    });
  });
  const handleModal = () => {
    setShowModal(false)
  }
  const handleCancel = () => {
    setShowModal(false);

  }
  const handleSubmit = () => {
    setModalLoading(true);
    if (storeEmail === "") {
      setModalLoading(false);

      return message.info("Please enter email address");
    }
    if (!isEmail(storeEmail)) {
      setModalLoading(false);

      return message.error("Please enter a valid email address");

    }




    if (storeInstruction === "") {
      setModalLoading(false);

      return message.info("Please enter Store Instruction ");
    }


    const updateData = {
      storeEmail,
      storeInstruction,

    };

    app.firestore().collection("stores").doc(props.userID).update(updateData).then(() => {
      setShowModal(false);
      setModalLoading(false);


      return message.success("Data saved");
    });

  }
  const {
    city,
    country,
    storeEmail,
    storeInstruction,
    ownerAddress,
    ownerFullName,
    postalCode,
    province,
    storeAddress,
    storeName,
  } = formData;
  return (
    <Card>
      <Modal
        title="Edit Account Settings"
        visible={showModal}
        onOk={handleSubmit}
        confirmLoading={modalLoading}
        onCancel={handleCancel}
      >
        <Row className="mb-3">
          <Text>Email Address</Text>
          <Input value={storeEmail} onChange={handleChange("storeEmail")} />
        </Row>


        <Row className="mb-3">
          <Text>Instruction </Text>
          <Input.TextArea rows={4} value={storeInstruction} multiple onChange={handleChange("storeInstruction")} />
        </Row>






      </Modal>
      <Row justify="center">
        <Col sm={24} md={23}>
          <div className="d-md-flex">
            <div
              className="rounded p-2 bg-white shadow-sm mx-auto"
              style={{
                marginTop: "-3.5rem",
                maxWidth: `${props.avatarSize + 16}px`,
              }}
            >
              <Avatar
                shape="square"
                size={props.avatarSize}
                src={props.userData.storeLogo}
              />
            </div>
            <div className="ml-md-4 w-100">
              <Flex
                alignItems="center"
                mobileFlex={false}
                className="mb-3 text-md-left text-center"
              >
                <h2 className="ml-2 mr-2">{props.userData.storeName}</h2>
                <Button
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  Settings
                </Button>
              </Flex>
            </div>
          </div>
        </Col>
      </Row>
    </Card>
  );
}



const StoreInfo = (props) => (
  <Card title="Store Information">
    <Row gutter={30} className={"ml-1"}>
      <p>Store Name: </p>
      <h4 className="font-weight-semibold"> {props.userData.storeName}</h4>
    </Row>
    <Row gutter={30} className={"ml-1"}>
      <p>Store Instruction : </p>
      <h4 className="font-weight-semibold"> {props.userData.storeInstruction}</h4>
    </Row>
    <Row gutter={30} className={"ml-1"}>
      <p>Store Address: </p>
      <h4 className="font-weight-semibold"> {props.userData.storeAddress}</h4>
    </Row>
    <Row gutter={30} className={"ml-1"}>
      <p>City: </p>
      <h4 className="font-weight-semibold"> {props.userData.storeCity}</h4>
    </Row>
    <Row gutter={30} className={"ml-1"}>
      <p>Province:</p>
      <h4 className="font-weight-semibold"> {props.userData.storeProvince}</h4>
    </Row>
    <Row gutter={30} className={"ml-1"}>
      <p>Country:</p>
      <h4 className="font-weight-semibold"> {props.userData.storeCountry}</h4>
    </Row>
    <Row gutter={30} className={"ml-1"}>
      <p>Postal Code:</p>
      <h4 className="font-weight-semibold"> {props.userData.storePostalCode}</h4>
    </Row>
  </Card>
);
const Networks = (props) => {
  let data = {
    city: props.userData.city,
    province: props.userData.province,
    country: props.userData.country,
  };
  if (props.userData.networks) {
    console.log(props.userData.networks[0]);
    data = {
      city: props.userData.networks[0].city,
      province: props.userData.networks[0].province,
      country: props.userData.networks[0].country,
    };
  }
  return (
    <Card title="Network">
      <Row gutter={30} className={"ml-1"}>
        <p>City: </p>
        <h4 className="font-weight-semibold">{data.city}</h4>
      </Row>
      <Row gutter={30} className={"ml-1"}>
        <p>Province: </p>
        <h4 className="font-weight-semibold">{data.province}</h4>
      </Row>
      <Row gutter={30} className={"ml-1"}>
        <p>Country: </p>
        <h4 className="font-weight-semibold">{data.country}</h4>
      </Row>
    </Card>
  )
};

const OwnerInfo = (props) => (
  <Card title="Owner Information">
    <div className="mb-3">
      <Row>
        <Col sm={24} md={22}>
          <AvatarStatus
            src={props.userData.storeLogo}
            name={props.userData.storeOwnerFullName}
            subTitle={props.userData.storeOwnerAddress}
          />
        </Col>
      </Row>
      <Row gutter={30} className={"ml-1 mt-4"}>
        <p>Mobile Number:</p>
        <h4 className="font-weight-semibold"> {props.userData.storeMobile}</h4>
      </Row>
      <Row gutter={30} className={"ml-1"}>
        <p>Email Address:</p>
        <h4 className="font-weight-semibold"> {props.userData.storeEmail}</h4>
      </Row>
    </div>
  </Card>
);



const StoreSchudle = (props) => {



  const [loadingInication, setLoadingIndication] = useState(false);
  let data = {
    monday: { from: "12:00 am", to: "12:00 pm" },
    tuesday: { from: "12:00 am", to: "12:00 pm" },
    wednesday: { from: "12:00 am", to: "12:00 pm" },
    thursday: { from: "12:00 am", to: "12:00 pm" },
    friday: { from: "12:00 am", to: "12:00 pm" },
    saturday: { from: "12:00 am", to: "12:00 pm" },
    sunday: { from: "12:00 am", to: "12:00 pm" },
  };
  if (props.userData.storeSchedule) {
    data = props.userData.storeSchedule;

  }


  const [formData, setFormData] = useState(data);
  const handleSubmit = () => {
    setLoadingIndication(true);
    app
      .firestore()
      .collection("stores")
      .doc(props.userID)
      .update({ storeSchedule: formData })
      .then(() => {
        setLoadingIndication(false);
        message.success("Store Schedule Saved");
      });
  }
  const convertTime12to24 = (time12h) => {
    const [time, modifier] = time12h.split(" ");

    let [hours, minutes] = time.split(":");

    if (hours === "12") {
      hours = "00";
    }

    if (modifier === "PM" || modifier === "pm") {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;
  };

  const handleChange = ((e, timeString, name) => {
    console.log({ timeString })


    setFormData({
      ...formData,
      [name]: {
        from: convertTime12to24(timeString[0]),
        to: convertTime12to24(timeString[1]),
      },
    });
  });
  return (
    <Card title="Store Schedule">
      <Row className="mb-3">
        <Text>Monday</Text>
        <RangePicker
          format={"hh:mm a"}
          key="Monday"
          style={{ width: "100%" }}
          onChange={(value, timeString) => {
            handleChange(value, timeString, "monday");
          }}
          defaultValue={[
            moment(formData.monday.from, "hh:mm a"),
            moment(formData.monday.to, "hh:mm a"),
          ]}
        />
      </Row>
      <Row className="mb-3">
        <Text>Tuesday</Text>
        <RangePicker
          format={"hh:mm a"}
          style={{ width: "100%" }}
          key="Tuesday"
          onChange={(value, timeString) => {
            handleChange(value, timeString, "tuesday");
          }}
          defaultValue={[
            moment(formData.tuesday.from, "hh:mm a"),
            moment(formData.tuesday.to, "hh:mm a"),
          ]}
        />
      </Row>
      <Row className="mb-3">
        <Text>Wednesday</Text>
        <RangePicker
          format={"hh:mm a"}
          style={{ width: "100%" }}
          onChange={(value, timeString) => {
            handleChange(value, timeString, "wednesday");
          }}
          defaultValue={[
            moment(formData.wednesday.from, "hh:mm a"),
            moment(formData.wednesday.to, "hh:mm a"),
          ]}
        />
      </Row>
      <Row className="mb-3">
        <Text>Thursday</Text>
        <RangePicker
          format={"hh:mm a"}
          style={{ width: "100%" }}
          onChange={(value, timeString) => {
            handleChange(value, timeString, "thursday");
          }}
          defaultValue={[
            moment(formData.thursday.from, "hh:mm a"),
            moment(formData.thursday.to, "hh:mm a"),
          ]}
        />
      </Row>
      <Row className="mb-3">
        <Text>Friday</Text>
        <RangePicker
          format={"hh:mm a"}
          style={{ width: "100%" }}
          onChange={(value, timeString) => {
            handleChange(value, timeString, "friday");
          }}
          defaultValue={[
            moment(formData.friday.from, "hh:mm a"),
            moment(formData.friday.to, "hh:mm a"),
          ]}
        />
      </Row>
      <Row className="mb-3">
        <Text>Saturday</Text>
        <RangePicker
          onChange={(value, timeString) => {
            handleChange(value, timeString, "saturday");
          }}
          format={"hh:mm a"}
          defaultValue={[
            moment(formData.saturday.from, "hh:mm a"),
            moment(formData.saturday.to, "hh:mm a"),
          ]}
          style={{ width: "100%" }}
        />
      </Row>
      <Row className="mb-3">
        <Text>Sunday</Text>
        <RangePicker
          onChange={(value, timeString) => {
            handleChange(value, timeString, "sunday");
          }}
          format={"hh:mm a"}
          style={{ width: "100%" }}
          name="sunday"
          defaultValue={[
            moment(formData.sunday.from, "hh:mm a"),
            moment(formData.sunday.to, "hh:mm a"),
          ]}
        />
      </Row>
      <Row>
        <Button
          type="primary"
          block
          loading={loadingInication}
          style={{ width: "100%" }}
          onClick={handleSubmit}
        >
          Save
        </Button>
      </Row>
    </Card>
  );
}

const Billing = (props) => {
  console.log({ props })
  // let feess = props.userData.fees;
  // let amount=props.userData.fees
  // let types = "fixedPrice";
  // if (feess != parseInt(feess)) {
  //   if (feess.includes("%")) {
  //     types = "percentage";
  //     amount = amount.split("%");
  //   }
  // }
  // const [type, setType] = useState(types);
  const [pickupServiceFee, setpickupServiceFee] = useState(props.userData.storePickupServiceFee);
  const [deliveryServiceFee, setdeliveryServiceFee] = useState(props.userData.storeDeliveryServiceFee);




  // const updateFees = useCallback((e) => {
  //   setFees(e);
  // });
  // const updateType = useCallback((e) => {
  //   console.log(e)
  //   setType(e);
  // });  
  // const submit = () => {
  //   if (fees==="") {
  //     return;
  //   }
  //   let feeAmount = fees;
  //   if (type==="percentage") {
  //     feeAmount += "%";
  //   }
  //   app.firestore().collection("stores").doc(props.userID).update({ fees: feeAmount }).then(() => {
  //     message.success("Fees Updated")
  //   });
  // }
  return (
    <Card title="Billing">
      <Row className="mb-3">
        <Text>Pickup Service Fee: {pickupServiceFee}</Text>
      </Row>
      <Row className="mb-3">
        <Text>Delivery Service Fee: {deliveryServiceFee}</Text>
      </Row>
    </Card>
  );
}


export class AccountSettings extends Component {
  listen = null;
  constructor(props) {
    super(props);

    this.state = {
      userData:this.props.userData?this.props.userData: {
        
        returnFee: "",
        returnRequests: "",
        serviceFee: "",
        storeAccountStatus: "",
        storeAddress: "",
        storeCity: "",
        storeCountry: "",
        storeDeliveryServiceFee: "",
        storeDeliveryServiceStatus: "",
        storeEmail: "",
        storeInstruction: "",
        storeLogo: "",
        storeMobile: "",
        storeName: "",
        storeNetworks: [],
        storeNote: "",
        storeOwnerAddress: "",
        storeOwnerFullName: "",
        storePickupServiceFee: "",
        storePickupServiceStatus: "",
        storePostalCode: "",
        storeProvince: "",
        storeSchedule: {},
        uploadedFiles: { idPicture2: '', storePicture: '', idPicture1: '' }

      },
      userId:'',
      orderID: "",

    };
  }

  componentDidMount() {
    console.log({ user: app.auth().currentUser.phoneNumber })
    const storeMobile = app.auth().currentUser.phoneNumber 
    this.listen = app.firestore().collection('stores')
    .where("storeMobile", "==", storeMobile)
    .onSnapshot((res) => {
      if (!res.empty) {
        console.log({res})
        res.forEach((doc) => {
          console.log({store:doc.data()})
          this.setState({ userData: doc.data(),userId:doc.id });
          console.log({ userData: this.props.userData, sss: doc.data() })

        });
      }
      // this.setState({ userData: snap.data() });
      

    })
  }

  componentWillUnmount() {
    if (this.listen != null) {
      this.listen();
    }
  }

  render() {
    const avatarSize = 150;
    return (
      <>
        <PageHeaderAlt
          background="/img/others/img-12.jpg"
          cssClass="bg-primary"
          overlap
        >
          <div className="container text-center">
            <div className="py-5 my-md-5"></div>
          </div>
        </PageHeaderAlt>
        <div className="container my-4">
          <ProfilePic
            userData={this.state.userData}
            avatarSize={avatarSize}
            userID={this.state.userId}
          />
          <Row gutter="16">
            <Col xs={24} sm={24} md={16}>
              <StoreInfo userData={this.state.userData} />
              <OwnerInfo userData={this.state.userData} />
              {/* <Networks
                userID={this.props.userId}
                userData={this.state.userData}
              /> */}
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Billing
                userData={this.state.userData}
                userID={this.state.userId}
              />
              <StoreSchudle
                userData={this.state.userData}
                userID={this.state.userId}
              />
            </Col>

            {/* <Col xs={24} sm={24} md={8}>
              <Connection userData={this.props.userData} />
            </Col> */}
          </Row>
        </div>
      </>
    );
  }
}

export default AccountSettings;
