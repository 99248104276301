import React from "react";
import { Layout,Typography,Avatar } from 'antd';
import { connect } from 'react-redux';
import { SIDE_NAV_WIDTH, SIDE_NAV_DARK, NAV_TYPE_SIDE } from 'constants/ThemeConstant';
import { Scrollbars } from 'react-custom-scrollbars';
import MenuContent from './MenuContent'

const { Sider } = Layout;
const {Text}=Typography
export const SideNav = ({navCollapsed, sideNavTheme, routeInfo, hideGroupTitle, localization = true,userData }) => {
  const props = { sideNavTheme, routeInfo, hideGroupTitle, localization, userData };
  
  return (
    <Sider
      className={`side-nav ${
        sideNavTheme === SIDE_NAV_DARK ? "side-nav-dark" : ""
      }`}
      width={SIDE_NAV_WIDTH}
      collapsed={navCollapsed}
    >
      <Scrollbars autoHide>
        {!navCollapsed && (
          <div style={{ marginLeft: 24, marginTop: 15,textAlign:'center' }}>
            <Avatar src={props.userData.storeLogo} size={50} />
            <h5 style={{ textAlign: "center",marginTop:10 }}>
              Welcome {props.userData.storeName}
            </h5>
            <h5 style={{  textAlign: "center" }}>
              Status {props.userData.storeAccountStatus}
            </h5>
          </div>
        )}
        {props.userData.storeAccountStatus === "Active" && (
          <MenuContent type={NAV_TYPE_SIDE} {...props} />
        )}
      </Scrollbars>
    </Sider>
  );
}

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, sideNavTheme } =  theme;
  return { navCollapsed, sideNavTheme }
};

export default connect(mapStateToProps)(SideNav);
