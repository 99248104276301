
import React, {useState,useEffect} from 'react'
import { Card, Table, Select, Input, Button, Badge, Menu } from 'antd';
// import ProductListData from "assets/data/product-list.data.json"
import { EyeOutlined, DeleteOutlined, SearchOutlined, PlusCircleOutlined } from '@ant-design/icons';
import AvatarStatus from 'components/shared-components/AvatarStatus';
import EllipsisDropdown from 'components/shared-components/EllipsisDropdown';
import Flex from 'components/shared-components/Flex'
import NumberFormat from 'react-number-format';
import { useHistory,Link } from "react-router-dom";
import utils from 'utils'
 import {app} from '../../../auth/FirebaseAuth'
const { Option } = Select

const getStockStatus = (status) => {
  if (status === "Active") {
    return (
      <>
        <Badge status="success" />
        <span>Active</span>
      </>
    );
  }
  if (status === "Pending") {
    return (
      <>
        <Badge status="warning" />
        <span>Pending</span>
      </>
    );
  }
  if (status === "Inactive") {
    return (
      <>
        <Badge status="error" />
        <span>Inactive</span>
      </>
    );
  }
    if (status === "Unapproved") {
      return (
        <>
          <Badge status="error" />
          <span>Unapproved</span>
        </>
      );
    }
  return null;
};


const Products = (props) => {
  let history = useHistory();
  const [ProductListData, setProductListData] = useState([]);
  const [list, setList] = useState([]);
  

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [categories, setCategories] = useState([]);
  

  useEffect(async() => {
    // You need to restrict it at some point
    // This is just dummy code and should be replaced by actual
    let cats = await app.firestore().collection("settings").doc("categories").get();
    setCategories(cats.data().options);

    if (props.userId != undefined) {
    const realtime= app
         .firestore()
         .collection("items")
        //  .where("storeID", "==", "VVtbOCWw1Z0K23Vu0w0R")
         .where("storeID", "==", props.userId)
         .onSnapshot((query) => {
           let list = [];
           query.forEach((doc) => {
             list.push({ ...doc.data(), id: doc.id });
           });
           setProductListData(list);
           setList(list)

         });
      return () => realtime();
      

    }
   
  }, []);
  const dropdownMenu = (row) => (
    <Menu>
      <Menu.Item onClick={() => viewDetails(row)}>
        <Flex alignItems="center">
          <EyeOutlined />
          <span className="ml-2">View Details</span>
        </Flex>
      </Menu.Item>
      <Menu.Item onClick={() => deleteRow(row)}>
        <Flex alignItems="center">
          <DeleteOutlined />
          <span className="ml-2">
            {selectedRows.length > 0
              ? `Delete (${selectedRows.length})`
              : "Delete"}
          </span>
        </Flex>
      </Menu.Item>
    </Menu>
  );

  const addProduct = () => {
    history.push(`/add-product`);
  };

  const viewDetails = (row) => {
    history.push(`/edit-product/${row.id}`);
  };

  const deleteRow = (row) => {
    const objKey = "id";
    let data = list;
    if (selectedRows.length > 1) {
      selectedRows.forEach((elm) => {
        data = utils.deleteArrayRow(data, objKey, elm.id);
        setList(data);
        setSelectedRows([]);
      });
    } else {
      data = utils.deleteArrayRow(data, objKey, row.id);
      setList(data);
    }
  };

  const tableColumns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Product",
      dataIndex: "name",
      render: (_, record) => (
        <div className="d-flex">
          <Link to={`/edit-product/${record.id}`}>
            <AvatarStatus
              size={60}
              type="square"
              src={record.itemImagesUrl[0]}
              name={record.title}
            />
          </Link>
        </div>
      ),
      sorter: (a, b) => utils.antdTableSorter(a, b, "name"),
    },
    {
      title: "Category",
      dataIndex: "itemCategory",
      sorter: (a, b) => utils.antdTableSorter(a, b, "itemCategory"),
    },
    {
      title: "Price",
      dataIndex: "itemOriginalPrice",
      render: (itemOriginalPrice) => (
        <div>
          <NumberFormat
            displayType={"text"}
            value={(Math.round(itemOriginalPrice * 100) / 100).toFixed(2)}
            prefix={"$"}
            thousandSeparator={true}
          />
        </div>
      ),
      sorter: (a, b) => utils.antdTableSorter(a, b, "itemOriginalPrice"),
    },
    {
      title: "Stock",
      dataIndex: "itemQuantity",
      sorter: (a, b) => utils.antdTableSorter(a, b, "itemQuantity"),
    },
    {
      title: "Status",
      dataIndex: "itemStatus",
      render: (itemStatus) => (
        <Flex alignItems="center">{getStockStatus(itemStatus)}</Flex>
      ),
      sorter: (a, b) => utils.antdTableSorter(a, b, "itemStatus"),
    },
    {
      title: "",
      dataIndex: "actions",
      render: (_, elm) => (
        <div className="text-right">
          <EllipsisDropdown menu={dropdownMenu(elm)} />
        </div>
      ),
    },
  ];

  const rowSelection = {
    onChange: (key, rows) => {
      setSelectedRows(rows);
      setSelectedRowKeys(key);
    },
  };

  const onSearch = (e) => {
    const value = e.currentTarget.value;
    const searchArray = e.currentTarget.value ? list : ProductListData;
    const data = utils.wildCardSearch(searchArray, value);
    setList(data);
    setSelectedRowKeys([]);
  };

  const handleShowCategory = (value) => {
    if (value !== "All") {
      const key = "itemCategory";
      const data = utils.filterArray(ProductListData, key, value);
      setList(data);
    } else {
      setList(ProductListData);
    }
  };

  return (
    <Card>
      <Flex alignItems="center" justifyContent="between" mobileFlex={false}>
        <Flex className="mb-1" mobileFlex={false}>
          <div className="mr-md-3 mb-3">
            <Input
              placeholder="Search"
              prefix={<SearchOutlined />}
              onChange={(e) => onSearch(e)}
            />
          </div>
          <div className="mb-3">
            <Select
              defaultValue="All"
              className="w-100"
              style={{ minWidth: 180 }}
              onChange={handleShowCategory}
              placeholder="Category"
            >
              <Option value="All">All</Option>
              {categories.map((elm) => (
                <Option key={elm.value} value={elm.value}>
                  {elm.label}
                </Option>
              ))}
            </Select>
          </div>
        </Flex>
        <div>
          <Button
            onClick={addProduct}
            type="primary"
            icon={<PlusCircleOutlined />}
            block
          >
            Add product
          </Button>
        </div>
      </Flex>
      <div className="table-responsive">
        <Table
          columns={tableColumns}
          dataSource={list}
          rowKey="id"
          // rowSelection={{
          //   selectedRowKeys: selectedRowKeys,
          //   type: "checkbox",
          //   preserveSelectedRowKeys: false,
          //   ...rowSelection,
          // }}
        />
      </div>
    </Card>
  );
};

export default Products;
