import {
  ShoppingCartOutlined,
  HomeOutlined,
  ShopOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from 'configs/AppConfig'

const dashBoardNavTree = [

  {
    key: "dashboard",
    path: `/home`,
    title: "Dashboard",
    icon: HomeOutlined,
    breadcrumb: false,
    submenu: [],
  },
];
const products = [
  {
    key: "products",
    path: `/products`,
    title: "products",
    icon: ShoppingCartOutlined ,
    breadcrumb: false,
    submenu: [],
  },
];
const orders = [
  {
    key: "orders",
    path: `/orders`,
    title: "orders",
    icon: ShopOutlined,
    breadcrumb: false,
    submenu: [],
  },
];
const accountSettings = [
  {
    key: "accountSettings",
    path: `/account-settings`,
    title: "Account settings",
    icon: SettingOutlined,
    breadcrumb: false,
    submenu: [],
  },
];


const navigationConfig = [...dashBoardNavTree, ...products,...orders,...accountSettings];

export default navigationConfig;
