import React, { useEffect, useCallback, useState } from "react";
import { message, Select } from "antd";
import { useHistory } from "react-router-dom";
import { app } from "../../../auth/FirebaseAuth";
import firebase from "firebase";
import isEmail from "validator/lib/isEmail";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import OtpInput from "react-otp-input";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";
import csc from "country-state-city";
 
import { LeftOutlined } from "@ant-design/icons";
import { Helmet } from "react-helmet";
 
// init firebaseStorageUpload function

var filterdCountryCode = ''

var provinceCode = ''
const firebaseStorageUpload = (file, bucketName, cb) => {
  app
    .storage()
    .ref(bucketName)
    .child(`${Date.now()}-${file.name}`)
    .put(file)
    .then((snapshot) => {
      snapshot.ref
        .getDownloadURL()
        .then((url) => {
          if (url) {
            // return
            return cb(null, url);
          }
        })
        .catch((error) => {
          return cb(error, null);
        });
    })
    .catch((error) => {
      return cb(error, null);
    });
};
let x = false
export const RegisterForm = (props) => {
  let history = useHistory();

  const [verifyPhone, setVerifyPhone] = useState(false);
  // init mobile Number
  const [MobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [phoneAuthRes, setPhoneAuthRes] = useState("");
  const [recaptcha, setRec] = useState("");
  const [countryCode, setCountryCode] = useState(false);
  const [countryList, setCountryList] = useState(csc.getAllCountries());
  useEffect(() => {

    console.log({ countryList })
    const reCaptchaVerifier = new firebase.auth.RecaptchaVerifier("r", {
      size: "invisible",
      callback: function (response) { },
    });
    reCaptchaVerifier.render();
    setRec(reCaptchaVerifier);
    let realtime = app.auth().onAuthStateChanged((user) => {
      if (!user) {

      } else {
        // user authenticated
        console.log('came back here')
        app
          .firestore()
          .collection("stores")
          .where("storeMobile", "==", user.phoneNumber)
          .get()
          .then((snap) => {
            //user did not fill the form stay here
            if (snap.empty) {
              console.log(x, 'formFilled');
              if (x === false) {
                // app
                //   .auth()
                //   .signOut()
                //   .then(() => {
                //     return message.warn("You need to create account to login");
                //   });
              }
            }
            //user already filled the form go to dashboard
            else {
              history.push("/home");
            }
          });
        //check if the user filled the store form
      }
    });
    return () => {
      if (realtime) {
        realtime();
      }
    }
  }, []);

  const [personalData, setPersonalData] = useState({
    storeName: "",
    fullName: "",
    emailAddress: "",
    imageID1: "",
    imageID2: "",
    ownerAddress: "",
  });

  // init store Info
  const [storeInfo, setStoreInfo] = useState({
    country: "",
    province: "",
    city: "",
    postalCode: "",
    address: "",
    storeInstruction: "",
    storePicture: "",
    storeLogo: "",
  });

  // init btnLoading state
  const [btnLoading, setBtnLoading] = useState(false);

  // init store info

  // destructure personalData
  const {
    storeName,
    fullName,
    emailAddress,
    imageID1,
    imageID2,
    ownerAddress,
  } = personalData;

  // destructure Store Info
  const {
    country,
    province,
    city,
    postalCode,
    address,
    storeInstruction,
    storeLogo,
    storePicture,
  } = storeInfo;

  // init handlePersonalDataChange function
  const handlePersonalDataChange = (data) => (e) => {
    setPersonalData({ ...personalData, [data]: e.target.value });
  };

  // init handleStoreInfoChange function
  const handleStoreInfoChange = (data) => (e) => {
    setStoreInfo({ ...storeInfo, [data]: e.target.value });
  };

  // handle country change
  const handleCountryChange = useCallback((country) => {

    filterdCountryCode = csc.getAllCountries().filter((data) => data.name === country.target.value )
   
    filterdCountryCode=filterdCountryCode[0].isoCode
    console.log({ country: country.target.value })
    setStoreInfo({ ...storeInfo, country: country.target.value });
  });

  // init handleProvinceChange
  const handleProvinceChange = useCallback((province) => {
   

      provinceCode = csc.getStatesOfCountry(filterdCountryCode).filter((data) => data.name === province.target.value )
   
      provinceCode=provinceCode[0].isoCode
    setStoreInfo({ ...storeInfo, province: province.target.value });
  });

  // init handleMobileNumber
  const handleMobileNumber = ({ phone }) => {
    // update Mobile Number
    setMobileNumber(phone);
  };

  // init personalDataFileUpload function
  const personalDataFileUpload = useCallback((data) => (e) => {
    // get file
    const _file = e.target.files[0];

    // invoke firebaseStorageUpload function
    firebaseStorageUpload(_file, "stores", (error, url) => {
      if (error) {
        return message.error(error.message);
      }

      // update personal Data state
      setPersonalData({ ...personalData, [data]: url });
    });
  });

  // init storeInfoFileUpload function
  const storeInfoFileUpload = useCallback((data) => (e) => {
    // get file
    const _file = e.target.files[0];

    // invoke firebaseStorageUpload function
    firebaseStorageUpload(_file, "stores", (error, url) => {
      if (error) {
        return message.error(error.message);
      }

      // update personal Data state
      setStoreInfo({ ...storeInfo, [data]: url });
    });
  });

  const handleOtpChange = (data) => {
    setOtp(data);
  };
  // ========= Init handle Submit function =========
  const handleSubmit = (e) => {
    // prevent default
    e.preventDefault();

    // update btnLoading state
    setBtnLoading(true);

    // validate
    if (!emailAddress) {
      // update btnLoading state
      setBtnLoading(false);

      return message.error("Please enter email address");
    }

    if (!storeName) {
      // update btnLoading state
      setBtnLoading(false);

      return message.error("Please enter store name");
    }
    if (!fullName) {
      // update btnLoading state
      setBtnLoading(false);

      return message.error("Please enter full name");
    }
    if (!MobileNumber) {
      // update btnLoading state
      setBtnLoading(false);

      return message.error("Please enter mobile number");
    }
    if (!imageID1) {
      // update btnLoading state
      setBtnLoading(false);

      return message.error("Please upload ID 1");
    }
    if (!imageID2) {
      // update btnLoading state
      setBtnLoading(false);

      return message.error("Please upload ID 2");
    }
    if (!country) {
      // update btnLoading state
      setBtnLoading(false);

      return message.error("Please select country");
    }
    if (!province) {
      // update btnLoading state
      setBtnLoading(false);

      return message.error("Please select province");
    }
    if (!city) {
      // update btnLoading state
      setBtnLoading(false);

      return message.error("Please enter city");
    }
    if (!postalCode) {
      // update btnLoading state
      setBtnLoading(false);

      return message.error("Please enter postal code");
    }
    if (!address) {
      // update btnLoading state
      setBtnLoading(false);

      return message.error("Please enter address");
    }
    if (!storeInstruction) {
      // update btnLoading state
      setBtnLoading(false);

      return message.error("Please enter Store Instruction");
    }
    if (!storeLogo) {
      // update btnLoading state
      setBtnLoading(false);

      return message.error("Please upload store logo");
    }
    if (!storePicture) {
      // update btnLoading state
      setBtnLoading(false);

      return message.error("Please upload store picture");
    }

    if (!isEmail(emailAddress)) {
      // update btnLoading state
      setBtnLoading(false);

      return message.error("Please enter valid email address");
    }
    setCountryCode(true);
    x = true;
    app
      .auth()
      .signInWithPhoneNumber("+" + MobileNumber, recaptcha)
      .then((res) => {

        setVerifyPhone(true);

        setPhoneAuthRes(res);
      })
      .catch((x) => {
        setBtnLoading(false);
        console.log({ x })
        return message.error("Invalid phone number");
      });
  };
  const handleOtpSubmit = (e) => {
    e.preventDefault();
    // let c =csc.getCountryByCode(country).name;
    // let p =csc.getStateByCodeAndCountry(province,country).name;
    phoneAuthRes
      .confirm(otp)
      .then((result) => {
        // User signed in successfully.
        //save to database
        let uploadedFiles = {
          storePicture,
          idPicture1: imageID1,
          idPicture2: imageID2,
        };
        // get register Data

        const registerData = {
          storeLogo,
          storeName,
          storeEmail: emailAddress,
          storeMobile: "+" + MobileNumber,
          storeCountry: country,
          storeProvince: province,
          storeCity: city,
          storePostalCode: postalCode,
          storeAddress: address,
          storeOwnerFullName: fullName,
          storeOwnerAddress: ownerAddress,

          storeInstruction,
          uploadedFiles,
          storeAccountStatus: "Pending",
        };
        console.log('here is the data', registerData)
        app
          .firestore()
          .collection("stores")
          .where("storeMobile", "==", "+" + MobileNumber)
          .get()
          .then((query) => {
            console.log("inserting account data", query.empty);
            if (query.empty) {
              app
                .firestore()
                .collection("stores")
                .add(registerData)
                .then((success) => {
                  console.log('data added');
                  // redirect to dashboard
                  history.push({ pathname: "/home" });
                  window.location.reload();
                })
                .catch((error) => {
                  // update btnLoading state
                  setBtnLoading(false);
                  history.push('/register');
                  return message.error(error.message);
                });
            } else {
              message.info("You already have account on our system");
              history.push({ pathname: "/home" });
              window.location.reload();
            }
          })
          .catch((error) => {
            return message.error(error.message);
            history.push("/404");
          });
      })
      .catch((error) => {
        // User couldn't sign in (bad verification code?)
        // ...
        return message.error(error.message);
      });
  };
  if (verifyPhone) {
    return (
      <React.Fragment>
        <Helmet>
          <title>Register</title>
          <link rel="stylesheet" href="/css/app-light.css" id="lightTheme" />
        </Helmet>

        <div class="wrapper vh-100 container">
          <div class="row align-items-center h-100">
            <div class="col-lg-12">
              <div class="w-50 mx-auto">
                <form class="mx-auto text-center">
                  <a
                    class="navbar-brand mx-auto mt-2 flex-fill text-center"
                    href="./index.html"
                  >
                    <h4>Register</h4>
                  </a>
                  <h1 class="h6 mb-3">
                    We've sent you sms please confirm to continue
                  </h1>

                  <div
                    className="row align-items-center h-100"
                    style={{
                      maxWidth: 300,
                      display: "flex",
                      margin: "auto",
                    }}
                  >
                    {/* Enter OTP Code */}
                    <div className="col-md-12">
                      <div class="form-group">
                        <OtpInput
                          inputStyle={{ width: "100%" }}
                          onChange={handleOtpChange}
                          value={otp}
                          numInputs={6}
                        />
                      </div>
                    </div>
                  </div>

                  <button
                    class="btn btn-lg btn-primary btn-block"
                    style={{
                      backgroundColor: "#212529",
                      color: "#fcfcfc",
                      borderColor: "#212529",
                      maxWidth: 300,
                      display: "block",
                      margin: "auto",
                    }}
                    onClick={handleOtpSubmit}
                    type="submit"
                  >
                    Confirm
                  </button>

                  <div id="recaptcha-container"></div>

                  <div className="mt-3">
                    <p>
                      <a
                        href="#"
                        onClick={() => {
                          app
                            .auth()
                            .signInWithPhoneNumber(
                              "+" + MobileNumber,
                              recaptcha
                            )
                            .then((res) => { })
                            .catch((x) => {
                              setBtnLoading(false);
                              console.log(x)
                              return message.error("Invalid phone number");
                            });
                        }}
                        style={{
                          color: "#212529",
                        }}
                      >
                        Resend Code
                      </a>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div id="r"></div>
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Helmet>
          <title>Register</title>
          <link rel="stylesheet" href="/css/app-light.css" id="lightTheme" />
        </Helmet>

        <div class="wrapper vh-100 container">
          <div class="row align-items-center h-100">
            <form
              class="col-lg-6 col-md-8 col-10 mx-auto"
              onSubmit={handleSubmit}
            >
              <div class="mx-auto text-center my-4">
                <div
                  style={{ display: "flex", cursor: "pointer" }}
                  onClick={() => {
                    history.push("/");
                  }}
                >
                  <LeftOutlined />
                  <h6 style={{ textAlign: "left" }}>Back </h6>
                </div>

                <a
                  class="navbar-brand mx-auto mt-2 flex-fill text-center"
                  href="./index.html"
                >
                  <h2>Seller Dashboard Logo</h2>
                </a>
                <h5 class="my-3">Create New Store</h5>
              </div>
              {/* Store Name */}
              <div class="form-group">
                <label for="storeName">Store Name</label>
                <input
                  value={storeName}
                  onChange={handlePersonalDataChange("storeName")}
                  type="text"
                  class="form-control"
                  id="storeName"
                />
              </div>

              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="fullName">Owner Full Name</label>
                  <input
                    value={fullName}
                    onChange={handlePersonalDataChange("fullName")}
                    type="text"
                    id="fullName"
                    class="form-control"
                  />
                </div>

                <div class="form-group col-md-6">
                  <label for="mobileNo">Mobile Number</label>
                  {/* <input value={} onChange={handlePersonalDataChange('mobileNumber')} type="text" id="mobileNo" class="form-control"/> */}
                  <PhoneInput
                    country={"us"}
                    value={MobileNumber}
                    onChange={(phone) => handleMobileNumber({ phone })}
                  />
                </div>
              </div>

              <hr class="my-4" />
              <div class="row mb-4">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="ownerAddress">Owner Address</label>
                    <input
                      value={ownerAddress}
                      onChange={handlePersonalDataChange("ownerAddress")}
                      class="form-control"
                      id="ownerAddress"
                    />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="email">Email Address</label>
                    <input
                      value={emailAddress}
                      onChange={handlePersonalDataChange("emailAddress")}
                      type="email"
                      class="form-control"
                      id="email"
                    />
                  </div>
                </div>

                {/* <div class="col-md-4">
                <div class="form-group">
                  <label for="password">Password</label>
                  <input value={password} onChange={handlePersonalDataChange('password')} type="password" class="form-control" id="password"/>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="confirmPassword">Confirm Password</label>
                  <input value={confirmPassword} onChange={handlePersonalDataChange('confirmPassword')} type="password" class="form-control" id="confirmPassword"/>
                </div>
              </div> */}
              </div>

              {/* File inputs */}
              <div className="row mb-4">
                <div className="col-md-6">
                  <div class="form-group mb-3">
                    <label for="customFile">Upload ID 1</label>
                    <div class="custom-file">
                      <input
                        onChange={personalDataFileUpload("imageID1")}
                        type="file"
                        class="custom-file-input"
                        id="id1"
                      />

                      <label class="custom-file-label" for="id1">
                        {imageID1 ? "ID 1 Added" : "Choose File"}
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div class="form-group mb-3">
                    <label for="customFile">Upload ID 2</label>
                    <div class="custom-file">
                      <input
                        onChange={personalDataFileUpload("imageID2")}
                        type="file"
                        class="custom-file-input"
                        id="id2"
                      />
                      <label class="custom-file-label" for="id2">
                        {imageID2 ? "ID 2 Added" : "Choose File"}
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <h5>Store Info</h5>

              <div className="row mb-4">
                <div className="col-md-6">
                  <div class="form-group mb-3">
                    <label for="country">Country</label>



                    <select
                      class="form-control"
                      onChange={(val) => {
                        // console.log({val:country.target.value});
                        handleCountryChange(val);
                      }}
                      value={country}
                    >
                      <option value=""></option>
                      {csc.getAllCountries().map((c) => {
                        return (
                          <option
                            value={c.name}
                            selected={country == c.name}
                          >
                            {c.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="col-md-6">
                  <div class="form-group mb-3">
                    <label for="province">Province</label>
                    <select
                      class="form-control"
                      value={province}
                      onChange={(val) => {
                        handleProvinceChange(val);
                      }}
                    >
                      <option value=""></option>
                      {csc.getStatesOfCountry(filterdCountryCode).map((c) => {
                        return (
                          <option
                            value={c.name}
                            selected={province == c.isoCode}
                          >
                            {c.name}
                          </option>
                        );
                      })}
                    </select>
                    {/* <RegionDropdown
                      className="form-control"
                      country={country}
                      value={province}
                      onChange={(val) => handleProvinceChange(val)}
                    /> */}
                  </div>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-md-6">
                  <div class="form-group mb-3">
                    <label for="city">City</label>
                    <select
                      class="form-control"
                      value={city}
                      onChange={handleStoreInfoChange("city")}
                    >
                      <option value=""></option>
                      {csc.getCitiesOfState(filterdCountryCode, provinceCode).map((c) => {
                        return (
                          <option value={c.name} selected={city == c.name}>
                            {c.name}
                          </option>
                        );
                      })}
                    </select>
                    {/* <input
                      className="form-control"
                      onChange={handleStoreInfoChange("city")}
                      value={city}
                    /> */}
                  </div>
                </div>

                <div className="col-md-6">
                  <div class="form-group">
                    <label for="postalCode">Postal Code</label>
                    <input
                      value={postalCode}
                      onChange={handleStoreInfoChange("postalCode")}
                      type="text"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>

              {/* Address */}
              <div class="form-group">
                <label for="address">Address</label>
                <input
                  value={address}
                  onChange={handleStoreInfoChange("address")}
                  type="text"
                  class="form-control"
                />
              </div>

              {/* storeInstruction */}
              <div class="form-group">
                <label for="storeInstruction">Store Instruction</label>
                <textarea
                  cols="5"
                  rows="4"
                  onChange={handleStoreInfoChange("storeInstruction")}
                  value={storeInstruction}
                  class="form-control"
                  id="storeInstruction"
                ></textarea>
              </div>

              {/* Store picture and logo */}
              <div className="row mb-4">
                <div className="col-md-6">
                  <div class="form-group mb-3">
                    <label for="customFile">Store Picture</label>
                    <div class="custom-file">
                      <input
                        onChange={storeInfoFileUpload("storePicture")}
                        type="file"
                        class="custom-file-input"
                        id="storePicture"
                      />
                      <label class="custom-file-label" for="id1">
                        {storePicture ? "Store Picture Added" : "Choose File"}
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div class="form-group mb-3">
                    <label for="customFile">Store Logo</label>
                    <div class="custom-file">
                      <input
                        type="file"
                        onChange={storeInfoFileUpload("storeLogo")}
                        class="custom-file-input"
                        id="storeLogo"
                      />
                      <label class="custom-file-label" for="id2">
                        {storeLogo ? "Store Logo Added" : "Choose File"}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {btnLoading ? (
                <button
                  class="btn btn-lg btn-block"
                  style={{
                    backgroundColor: "#212529",
                    color: "#fcfcfc",
                  }}
                  type="submit"
                  disabled
                >
                  Loading...
                </button>
              ) : (
                <button
                  class="btn btn-lg btn-block"
                  id="ss"
                  style={{
                    backgroundColor: "#212529",
                    color: "#fcfcfc",
                  }}
                  type="submit"
                >
                  Create
                </button>
              )}

              <p class="mt-5 mb-3 text-muted text-center">© 2021</p>
            </form>
          </div>
          <div id="r"></div>
        </div>
      </React.Fragment>
    );
  }
};

export default RegisterForm;
