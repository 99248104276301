import { app } from "../../../auth/FirebaseAuth";
const ReturnRequest = ({ id, orderData }) => {
    const {
        returnOrderReason,
        orderStatus,
        returnOrderTotal,
        returnFees,
        itemPrice,
        paymentStatus,
    } = orderData[0];
    const editOnData = () => app.firestore().collection("orders").doc(id);
    const acceptHandler = () => {
        const updateData = editOnData();
        const isPercent = returnFees.includes("%");
        const returnOrderFees = isPercent
            ? itemPrice * (parseInt(returnFees) / 100)
            : parseInt(returnFees);
        console.log(itemPrice, returnOrderFees);
        updateData.update({
            orderStatus: "Return in process",
            returnOrderFees,
            returnOrderTotal: itemPrice - returnOrderFees,
        });
    };
    const rejectHandler = () => {
        const updateData = editOnData();
        updateData.update({
            orderStatus: "Return Request has been rejected",
        });
    };

    const renderJsx = () => {
        if (
            orderStatus.toLowerCase() === "Return in process".toLowerCase() ||
            orderStatus.toLowerCase() === "Return Completed".toLowerCase()
        ) {
            return (
                <>
                    <div className="buttonsInfo">
                        <button className="info">
                            Return request accepted
                        </button>
                        <button className="info">{orderStatus}</button>
                    </div>
                    <div className="information">
                        <div className="payment-status">
                            <span>Return</span> <small>Payment Status:</small>{" "}
                            <b>{paymentStatus}</b>
                        </div>
                        <div className="payments">
                            <span className="fees">
                                Fees (
                                {returnFees.includes("%")
                                    ? returnFees
                                    : returnFees + "$"}
                                )
                            </span>

                            <h3>Return Total: CAD${returnOrderTotal}</h3>
                        </div>
                    </div>
                </>
            );
        } else if (orderStatus === "Return Request has been rejected") {
            return (
                <div className="buttonsInfo">
                    <button className="info">Return request Rejected</button>
                </div>
            );
        } else {
            return (
                <div className="buttons">
                    <button onClick={acceptHandler} className="accept">
                        Accept Return
                    </button>
                    <button onClick={rejectHandler} className="reject">
                        Reject
                    </button>
                </div>
            );
        }
    };

    return (
        <div className="returnRequest">
            <div className="content">
                <div className="heading">
                    <h2 className="title">Return Request</h2>
                    <h5 className="subtitle">Reason: </h5>
                    <p className="reason">{returnOrderReason}</p>
                </div>
                {renderJsx()}
            </div>
        </div>
    );
};

export default ReturnRequest;
