import React, { useState,useEffect } from 'react';
import { Menu, Dropdown, Badge, Avatar, List, Button } from 'antd';
import { 
  MailOutlined, 
  BellOutlined, 
  WarningOutlined,
  CheckCircleOutlined
} from '@ant-design/icons';
import Flex from 'components/shared-components/Flex';
import { app } from '../../auth/FirebaseAuth';
import moment from 'moment';
import {Link, useHistory } from 'react-router-dom';
const getIcon =  icon => {
  switch (icon) {
    case 'mail':
      return <MailOutlined />;
    case 'alert':
      return <WarningOutlined />;
    case 'check':
      return <CheckCircleOutlined />
    default:
      return <MailOutlined />;
  }
}

const GetNotificationBody = list => {
  let history = useHistory();
  return list.length > 0 ? (
    <List
      size="small"
      itemLayout="horizontal"
      dataSource={list}
      renderItem={(item) => (
        <Link to={`/orders/${item.id}`} target="_blank">
          <List.Item
            className="list-clickable"
            onClick={() => {
              item.handle();
            }}
          >
            <Flex alignItems="center">
              {/* <div className="pr-3">
            {item.img? <Avatar src={`/img/avatars/${item.img}`} /> : <Avatar className={`ant-avatar-${item.type}`} icon={getIcon(item.icon)} />}
          </div> */}
              <div className="mr-3">
                <span className="font-weight-bold text-dark">{item.name} </span>
                <span className="text-gray-light">{item.desc}</span>
              </div>
              <small className="ml-auto">{item.time}</small>
            </Flex>
          </List.Item>
        </Link>
      )}
    />
  ) : (
    <div className="empty-notification">
      <img
        src="https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg"
        alt="empty"
      />
      <p className="mt-3">You have viewed all notifications</p>
    </div>
  );
}

export const NavNotification = (props) => {
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  useEffect(() => {
    let newOrders = app.firestore().collection('orders')
      .where('orderStatus', '==', 'Pending store approval')
      .where('storeID', '==', props.userID)
      .onSnapshot((snap) => {
        let notiData = [];
        let count = 0;
        if (snap.empty) {
          
        }
        else {
           snap.forEach((doc) => {
             let data = doc.data();
             count++;
             notiData.push({
               time: moment(data.orderCreatedAt.toDate()).fromNow(),
               desc: "please proccess the order",
               name: "You have unanswered order",
               id: doc.id,
               handle:()=>{setVisible(false)}
             });
           });
        }
       
           setCount(count);
           setData(notiData);
      })
    return () => newOrders();
  },[])
  const handleVisibleChange = (flag) => {
    setVisible(flag);
  }

  const notificationList = (
    <div className="nav-dropdown nav-notification">
      <div className="nav-notification-header d-flex justify-content-between align-items-center">
        <h4 className="mb-0">Notification</h4>
        {/* <Button type="link" onClick={() => setData([])} size="small">Clear </Button> */}
      </div>
      <div className="nav-notification-body">
        {GetNotificationBody(data)}
      </div>
      {/* {
        data.length > 0 ? 
        <div className="nav-notification-footer">
          <a className="d-block" href="#/">View all</a>
        </div>
        :
        null
      } */}
    </div>
  );

  return (
    <Dropdown 
      placement="bottomRight"
      overlay={notificationList}
      onVisibleChange={handleVisibleChange}
      visible={visible}
      trigger={['click']}
    >
      <Menu mode="horizontal">
        <Menu.Item>
          <Badge count={count}>
            <BellOutlined className="nav-icon mx-auto" type="bell" />
          </Badge>
        </Menu.Item>
      </Menu>
    </Dropdown>
  )
}


export default NavNotification;
